import request from '@/utils/request'
import Vuetify from '@/plugins/vuetify'
var langId = Vuetify.preset.lang.current

export function fetchSubscriptionInvoice (param) {
  return request({
    url: langId + '/subscriptionInvoice/',
    method: 'get',
    params: param
  })
}
