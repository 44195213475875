<template>
    <div>
        <v-card flat>
          <div>
            <v-container
              class="white lighten-5 fill-height"
              style="width: 100%;">
              <div style="width: 100%;">
                  <v-card
                    class="pa-2"
                    width="100%">
                    <v-card-title class="justify-space-between">
                      <span class="mr-1" style="color: #6A6A6A">
                        <v-select
                          :items="allTypes"
                          :clearable="true"
                          label="All Types"
                          style="width: 121px;"
                          @change="handleFilterType"
                        />
                      </span>
                      <span style="color: #6A6A6A">
                        <v-select
                          :items="allPackages"
                          :clearable="true"
                          label="All Packages"
                          item-text="name"
                          item-value="id"
                          style="width: 121px;"
                          @change="handleFilterPackage"
                        />
                      </span>
                      <v-spacer />
                      <v-btn
                        rounded
                        outlined
                        class="text-capitalize"
                        style="margin-right: 8px"
                        @click="handleExport">
                        <v-icon left>$vuetify.icons.download</v-icon>
                        {{ lang.t('$vuetify.download') }}
                      </v-btn>
                      <v-btn
                        rounded
                        outlined
                        class="text-capitalize"
                        style="margin-right: 8px">
                        <v-icon left>$vuetify.icons.print</v-icon>
                        {{ lang.t('$vuetify.print') }}
                      </v-btn>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                        style="width: 10px;"
                        @change="handleSearch"
                      />
                    </v-card-title>
                    <v-skeleton-loader
                      :loading="listLoading"
                      :transition="transition"
                      type="table"
                    >
                      <v-data-table
                        :headers="headers"
                        :items="data"
                        :search="search"
                        :items-per-page="15"
                        hide-default-footer
                        class="mytable"
                        style="border-bottom: none"
                        @click:row="handleEdit">
                        <template v-slot:item.no="{ item }">
                            <span style="color: #00D79E">{{ item.no }}</span>
                        </template>
                        <template v-slot:item.com_name="{ item }">
                            <span class="text-capitalize">{{ item.com_name }}</span>
                        </template>
                        <template v-slot:item.type="{ item }">
                            <span class="text-capitalize">{{ item.type }}</span>
                        </template>
                        <template v-slot:item.payment_status_desc="{ item }">
                            <div v-if="item.payment_status === 0">
                                <span class="text-uppercase" style="color: #F59700">{{ item.payment_status_desc }}</span>
                            </div>
                            <div v-if="item.payment_status === 1">
                                <span class="text-uppercase" style="color: #2882F5">{{ item.payment_status_desc }}</span>
                            </div>
                        </template>
                        <template v-slot:item.transaction_status_desc="{ item }">
                            <v-chip v-if="item.transaction_status === 0" class="text-capitalize" color="#F59700" outlined small style="background: #FEF2E0">{{ item.transaction_status_desc }}</v-chip>
                            <v-chip v-if="item.transaction_status === 1" class="text-capitalize" color="#2882F5" outlined small style="background: #EBF4FF">{{ item.transaction_status_desc }}</v-chip>
                            <v-chip v-if="item.transaction_status === 3" class="text-capitalize" color="#F5295A" outlined small style="background: #F5295A">{{ item.transaction_status_desc }}</v-chip>
                        </template>
                      </v-data-table>
                    </v-skeleton-loader>
                  </v-card>
              </div>
              <div
                class="align-center"
                style="width: 100%">
                  <v-spacer></v-spacer><br>
                  <v-pagination
                  v-model="page"
                  color="#00B082"
                  :length="total_page"
                  :total-visible="7"
                  circle
                  @input="changePage"
                  ></v-pagination>
              </div>
            </v-container>
          </div>
        </v-card>
    </div>
</template>

<script>
import { fetchSubscriptionInvoice } from '@/api/zenwel-biz/subscription'
// import DialogCreate from './details/create-voucher'
// import DialogEdit from './details/edit-voucher'

export default {
  name: 'ListVoucher',

  components: {
    // DialogCreate,
    // DialogEdit
  },

  data () {
    return {
      headers: [
        {
          text: 'Billing Number',
          align: 'left',
          value: 'no'
        },
        {
          text: 'Date',
          align: 'left',
          value: 'created'
        },
        {
          text: 'Business Name',
          align: 'left',
          value: 'com_name'
        },
        {
          text: 'Activity',
          align: 'left',
          value: 'type'
        },
        {
          text: 'Price',
          align: 'left',
          value: 'fprice'
        },
        {
          text: 'Created By',
          align: 'left',
          value: 'created_by'
        },
        {
          text: 'Pay. Status',
          align: 'left',
          value: 'payment_status_desc'
        },
        {
          text: 'Billing Status',
          align: 'left',
          value: 'transaction_status_desc'
        }
      ],
      search: '',
      data: [],
      listLoading: true,
      transition: 'scale-transition',
      page: 0,
      total_page: 0,
      showDialogCreate: false,
      showDialogEdit: false,
      allTypes: ['Upgrade', 'Extend', 'Downgrade'],
      allPackages: [
        { id: 1, name: 'Free' },
        { id: 2, name: 'Basic' },
        { id: 3, name: 'Pro' }
      ],
      paramsQuery: {
        page: 1,
        per_page: 10,
        sort_type: 'desc',
        sort_column: 'id'
      },
      voucherID: ''
    }
  },

  computed: {
    lang () {
      return this.$vuetify.lang
    }
  },

  mounted () {
    this.getData()
  },

  methods: {
    getData () {
      this.listLoading = true
      fetchSubscriptionInvoice(this.paramsQuery).then((response) => {
        this.data = response.data.data
        this.total_page = response.data.meta.last_page
        this.page = response.data.meta.current_page
        this.listLoading = false
      }).catch((error) => {
        console.log(error)
      })
    },
    getNext () {
      this.getData(this.page)
    },
    changePage (item) {
      this.paramsQuery = {
        page: item,
        per_page: 10,
        sort_type: 'desc',
        sort_column: 'id'
      }
      this.getData()
    },
    // handleExport () {
    //   exportFile().then((response) => {
    //     var fileURL = window.URL.createObjectURL(new Blob([response.data]))
    //     var fileLink = document.createElement('a')
    //     fileLink.href = fileURL
    //     fileLink.setAttribute('download', 'file.xlsx')
    //     document.body.appendChild(fileLink)
    //     fileLink.click()
    //   }).catch((error) => {
    //     console.log(error)
    //   })
    // },
    handleFilterType (e) {
      this.paramsQuery = {
        type: e
      }
      this.getData()
    },
    handleFilterPackage (e) {
      this.paramsQuery = {
        package: e
      }
      this.getData()
    },
    handleSearch (e) {
      this.paramsQuery = {
        search: e
      }
      this.getData()
    },
    handleAddVoucher () {
      this.showDialogCreate = true
      this.voucherID = ''
    },
    handleEdit (e) {
      this.voucherID = e.id.toString()
      this.showDialogEdit = true
    }
  }
}
</script>
